/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { generatePath, matchPath } from "react-router-dom";
import { stringify as stringifyQs, parse as parseQs } from "qs";
import { BeforeNavigateHookHandler, INavigationResolver, IRoutingManager } from "@hexio_io/hae-lib-core";
import { createEventEmitter, createHook, emitEvent, Hook, isNumber, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IRouteListEntry, IRouteResolver } from "@hexio_io/hae-lib-blueprint";
import { IAppClientConfig_RoutesPathMap } from "../../shared/IAppClientConfig";
import {
	IResolvedLink,
	IResolvedLocation,
	IResolveLinkOpts,
	isLinkExternal,
	TLinkLocationSpec
} from "@hexio_io/hae-lib-components";

export class RoutingManager implements IRoutingManager {
	/** Route resolver */
	public routeResolver: IRouteResolver;

	/** Navigation resolver */
	public navigationResolver: INavigationResolver;

	/**
	 * Navigate event - emitted when location change
	 */
	public onNavigate: TSimpleEventEmitter<void>;

	/**
	 * Hook that is called before navigation.
	 */
	public beforeNavigateHook: Hook<BeforeNavigateHookHandler>;

	/** Prevent navigation flag */
	private preventNavigate = false;

	/**
	 * Constructor
	 */
	public constructor(private routesPathMap: IAppClientConfig_RoutesPathMap) {
		this.onNavigate = createEventEmitter();
		this.beforeNavigateHook = createHook();

		this.routeResolver = {
			getRouteByName: (routeKey) => this.getRouteByName(routeKey),
			getRouteList: () => {
				return Object.keys(this.routesPathMap).map((routeKey) => ({
					key: routeKey,
					label: this.routesPathMap[routeKey],
					path: this.routesPathMap[routeKey]
				}));
			},
			onInvalidate: createEventEmitter()
		};

		this.navigationResolver = {
			getCurrentLocation: () => this.getCurrentLocation(),
			resolveLink: (linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts) =>
				this.resolveLink(linkSpec, opts),
			onNavigate: this.onNavigate,
			beforeNavigateHook: this.beforeNavigateHook
		};

		this.handlePopState = this.handlePopState.bind(this);
		window.addEventListener("popstate", this.handlePopState);

		this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
		window.addEventListener("beforeunload", this.handleBeforeUnload);

		if (window.APP_DEBUG) {
			console.debug("[RoutingManager] Configured routes path map:", routesPathMap);
		}
	}

	private handlePopState(ev: PopStateEvent) {
		this.handleBeforeNavigateHook(0).then((canNavigate) => {
			if (canNavigate) {
				if (window.APP_DEBUG) {
					console.log("[RoutingManager] Navigate");
				}

				emitEvent(this.onNavigate);
			} else {
				ev.preventDefault();
				console.debug("[RoutingManager] Navigation prevented by hook");
				return false;
			}
		});
	}

	private handleBeforeUnload(ev: BeforeUnloadEvent) {
		if (this.preventNavigate) {
			ev.preventDefault();
			ev.returnValue = "";
		}
	}

	private async handleBeforeNavigateHook(path: string|number): Promise<boolean> {
		try {
			const result = await Promise.all(this.beforeNavigateHook.handlers.map((handler) => handler(path)));
			const hasFalseResult = result.some((r) => r === false);

			if (this.preventNavigate) {
				return false;
			}

			return hasFalseResult ? false : true;
		} catch (err) {
			console.error("[RoutingManager] Error in beforeNavigate hook", err);
			return true;
		}
	}

	/**
	 * Return current location
	 */
	public getCurrentLocation(): IResolvedLocation {
		return {
			pathname: location.pathname,
			hash: location.hash.substr(1),
			query: parseQs(location.search.substr(1))
		};
	}

	/**
	 * Returns route object by key
	 *
	 * @param routeKey Route name
	 */
	private getRouteByName(routeKey: string): IRouteListEntry {
		const route = this.routesPathMap[routeKey];

		if (route) {
			return {
				key: routeKey,
				label: this.routesPathMap[routeKey],
				path: this.routesPathMap[routeKey]
			};
		} else {
			return null;
		}
	}

	/**
	 * Navigates to a new path (without reload)
	 *
	 * @param path New path
	 */
	public navigate(path: string | number): void {
		this.handleBeforeNavigateHook(path).then((canNavigate) => {
			if (!canNavigate) {
				console.debug("[RoutingManager] Navigation prevented by hook");
				return;
			}

			if (isNumber(path)) {
				history.go(path);
			} else if (path.startsWith("/auth") || (isLinkExternal(path) && !path.startsWith(location.origin))) {
				location.href = path;
			} else {
				history.pushState("", "", path);
			}
	
			emitEvent(this.onNavigate);
		});
	}

	/**
	 * Sets prevent navigation flag
	 * If set to true, navigation will be blocked incl. browser back/forward/close tab
	 *
	 * @param prevent Prevent navigation flag
	 */
	public setPreventNavigation(prevent: boolean) {
		this.preventNavigate = prevent;
	}

	/**
	 * Resolves link based on link schema spec and options
	 *
	 * @param linkSpec Location specification
	 * @param opts Options
	 */
	public resolveLink(linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts): IResolvedLink {
		const location = this.getCurrentLocation();

		let linkUrl: string;
		let linkPath: string = null;

		switch (linkSpec.type) {
			case "ROUTE": {
				const route = this.getRouteByName(linkSpec.value.ROUTE.name);

				if (!route) {
					linkUrl = "#route-not-found";
					break;
				}

				try {
					linkUrl = linkPath = generatePath(route.path, linkSpec.value.ROUTE.params);
					const qs = stringifyQs(linkSpec.value.ROUTE.queryParams, {
						skipNulls: !linkSpec.value.ROUTE.serializeNullParams
					});

					if (qs) {
						linkUrl = linkUrl + "?" + qs;
					}
				} catch (err) {
					linkUrl = "#route-has-invalid-params";
					break;
				}

				break;
			}

			case "VIEW": {
				const route = this.getRouteByName("view");

				if (!route) {
					linkUrl = "#view-route-not-configured";
					break;
				}

				try {
					linkPath = generatePath(route.path, {
						viewId: linkSpec.value.VIEW.viewId
					});

					linkUrl =
						linkPath +
						"?" +
						stringifyQs({
							viewParams: linkSpec.value.VIEW.params
						});
				} catch (err) {
					linkUrl = "#view-route-has-invalid-path-configured";
					break;
				}

				break;
			}

			case "URL": {
				linkUrl = linkPath = linkSpec.value.URL;

				break;
			}

			case "NONE": {
				linkUrl = "";

				break;
			}
		}

		return {
			url: linkUrl,
			isExternal: isLinkExternal(linkUrl),
			match: linkPath
				? matchPath(location.pathname, {
					path: linkPath,
					exact: opts.exact,
					strict: opts.strict
				})
				: null
		};
	}

	public dispose() {
		window.removeEventListener("popstate", this.handlePopState);
		window.removeEventListener("beforeunload", this.handleBeforeUnload);
	}
}
