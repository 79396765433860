/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Terms for Blueprints
 */
export const blueprintsTerms = {
	base: {
		id: {
			label: "blueprint:base.id#label",
			description: "blueprint:base.id#description"
		},
		label: {
			label: "blueprint:base.label#label",
			description: "blueprint:base.label#description"
		},
		description: {
			label: "blueprint:base.description#label",
			description: "blueprint:base.description#description"
		},
		attributes: {
			label: "blueprint:base.attributes#label",
			description: "blueprint:base.attributes#description",
			allowWrite: {
				label: "blueprint:base.attributes.allowWrite#label",
				description: "blueprint:base.attributes.allowWrite#description"
			},
			allowRemove: {
				label: "blueprint:base.attributes.allowRemove#label",
				description: "blueprint:base.attributes.allowRemove#description"
			},
			allowRename: {
				label: "blueprint:base.attributes.allowRename#label",
				description: "blueprint:base.attributes.allowRename#description"
			},
			hidden: {
				label: "blueprint:base.attributes.hidden#label",
				description: "blueprint:base.attributes.hidden#description"
			}
		},
		metadata: {
			label: "blueprint:base.metadata#label",
			description: "blueprint:base.metadata#description"
		}
	},
	view: {
		root: {
			label: "blueprint:view.root#label"
		},
		params: {
			label: "blueprint:view.params#label",
			description: "blueprint:view.params#description"
		},
		title: {
			label: "blueprint:view.title#label",
			description: "blueprint:view.title#description"
		},
		description: {
			label: "blueprint:view.description#label",
			description: "blueprint:view.description#description"
		},
		requireAuthenticatedUser: {
			label: "blueprint:view.requireAuthenticatedUser#label",
			description: "blueprint:view.requireAuthenticatedUser#description"
		},
		authCondition: {
			label: "blueprint:view.authCondition#label",
			description: "blueprint:view.authCondition#description"
		},
		preload: {
			label: "blueprint:view.preload#label",
			description: "blueprint:view.preload#description"
		},
		dataSources: {
			label: "blueprint:view.dataSources#label",
			description: "blueprint:view.dataSources#description"
		},
		content: {
			label: "blueprint:view.content#label",
			description: "blueprint:view.content#description"
		},
		outlets: {
			root: {
				label: "blueprint:view.outlets.root#label",
				description: "blueprint:view.outlets.root#description"
			},
			propName: {
				label: "blueprint:view.outlets.propName#label",
				description: "blueprint:view.outlets.propName#description",
				placeholder: "blueprint:view.outlets.propName#placeholder"
			},
			value: {
				label: "blueprint:view.outlets.value#label",
				description: "blueprint:view.outlets.value#description"
			}
		},
		events: {
			root: {
				label: "blueprint:view.events.root#label"
			},
			onInit: {
				label: "blueprint:view.events.onInit#label",
				description: "blueprint:view.events.onInit#description"
			},
			onParamsChanged: {
				label: "blueprint:view.events.onParamsChanged#label",
				description: "blueprint:view.events.onParamsChanged#description"
			},
			onBeforeNavigate: {
				label: "blueprint:view.events.onBeforeNavigate#label",
				description: "blueprint:view.events.onBeforeNavigate#description"
			}
		},
		reInitOnParamsChanged: {
			label: "blueprint:view.reInitOnParamsChanged#label",
			description: "blueprint:view.reInitOnParamsChanged#description"
		}
	},
	manifest: {}
};
