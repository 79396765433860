export type HookHandler = (...args: unknown[]) => Promise<unknown>;

export type HookHandlerResult<Handler extends HookHandler> = Promise<Awaited<ReturnType<Handler>>[]>;

export interface Hook<Handler extends HookHandler> {
	handlers: Handler[];
}

export function createHook<Handler extends HookHandler>(): Hook<Handler> {
	return {
		handlers: []
	};
}

export function addHook<Handler extends HookHandler>(hook: Hook<Handler>, handler: Handler) {
	hook.handlers.push(handler);
}

export function removeHook<Handler extends HookHandler>(hook: Hook<Handler>, handler: Handler) {
	hook.handlers = hook.handlers.filter((h) => h !== handler);
}

export function processHookInParallel<Handler extends HookHandler>(hook: Hook<Handler>): HookHandlerResult<Handler> {
	return Promise.all(hook.handlers.map((handler) => handler())) as HookHandlerResult<Handler>;
}
